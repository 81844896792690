<mat-card class="about-card">
  <mat-card-header>
    <div mat-card-avatar class="about-card-image"></div>
    <mat-card-title>EJ C</mat-card-title>
    <mat-card-subtitle>a.k.a noredlace a.k.a lizardsticks</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Not much to see...
      Just go to <a href="https://plex.noredlace.com">Plex</a>
    </p>
    
    <!--
    <p *ngIf="apiJokeModels$">
      {{apiJokeModels$.contents.jokes.description}} : {{apiJokeModels$.contents.jokes.joke.title}}
  </p>
  <p *ngIf="apiJokeModels$">
    {{apiJokeModels$.contents.jokes.joke.text}}
  </p>
      -->
  </mat-card-content>
</mat-card>
