<main>
  <app-header></app-header>

  <!--<app-server-card></app-server-card>-->
  <router-outlet></router-outlet>
  <!-- Router Views Go Here-->

</main>


